<template>
  <vx-card style="padding-left: 10px">
    <vs-prompt
      title="Export as CSV"
      class="export-options"
      @cancle="clearFields"
      @accept="exportToExcel"
      accept-text="Export"
      @close="clearFields"
      :active.sync="activePrompt"
    >
      <vs-input
        v-model="fileName"
        placeholder="Enter File Name.."
        class="w-full"
      />
    </vs-prompt>
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="vehicles"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >
          <vx-tooltip text="Export Vehicles" position="top" delay=".3s">
            <vs-button
              type="gradient"
              size="small"
              icon-pack="feather"
              class="ml-1"
              icon="icon-download"
              color="danger"
              @click="getVehicleForCSV"
              >Export</vs-button
            >
          </vx-tooltip>
          <div class="flex">
            <div class="v-select-filter-container">
              <v-select
                v-model="dataTableParams.filter"
                class="mr-4"
                :options="filterOptions"
                :clearable="false"
              />
            </div>
            <div class="v-select-limit-container">
              <v-select
                v-model="dataTableParams.limit"
                class="mr-4"
                :options="limitOptions"
                :clearable="false"
              />
            </div>
            <div class="flex  flex-end">
              <vs-input icon-pack="feather" icon="icon-search" placeholder="Search" v-model="dataTableParams.search" class="w-full"/>
            </div>
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="make">Owner</vs-th>
          <vs-th >Image</vs-th>
          <vs-th sort-key="make">Make</vs-th>
          <vs-th sort-key="model">Model</vs-th>
          <vs-th sort-key="year">Year</vs-th>
          <vs-th sort-key="sellerType">Listing Type</vs-th>
          <vs-th sort-key="status">Status</vs-th>
          <vs-th sort-key="createdAt">Created At</vs-th>
          <vs-th sort-key="action" size="25%">Actions</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr]._id">{{ data[indextr].dealerId ? data[indextr].dealerId.name : (data[indextr].userId.fullName ? data[indextr].userId.fullName : data[indextr].userId.firstName) }}</vs-td>
            
            <vs-td class="td vs-table--td" :data="data[indextr].mainImage">
              <div class="img-container">
                <div class="image">
                  <img @click="viewDetailHandler(data[indextr]._id)" :src="data[indextr].mainImage"/>
                </div> 
              </div>
            </vs-td>
            <vs-td :data="data[indextr].make">{{ data[indextr].make }}</vs-td>
            <vs-td :data="data[indextr].model">{{ data[indextr].model }}</vs-td>
            <vs-td :data="data[indextr].year">{{ data[indextr].year }}</vs-td>
            <vs-td :data="data[indextr].sellerType">{{ checkSellerType(data[indextr].sellerType, data[indextr].isAuction) }}</vs-td>
            <vs-td :data="data[indextr].status">{{
              formatAuctionStatus(data[indextr].status)
            }}</vs-td>
            <vs-td :data="data[indextr].createdAt">{{
              moment(data[indextr].createdAt).format("DD/MM/YYYY")
            }}</vs-td>
           
            <vs-td :data="data[indextr]._id">
              <vs-row vs-type="flex" vs-justify="space-between">
                <vx-tooltip text="View" position="top" delay=".3s">
                  <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    @click="viewDetailHandler(data[indextr]._id)"
                    icon="icon-info"
                    color="success"
                  ></vs-button>
                </vx-tooltip>
                <vx-tooltip text="Edit" position="top" delay=".3s">
                  <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    @click="editDetailHandler(data[indextr]._id)"
                    icon="icon-edit"
                    color="warning"
                  ></vs-button>
                </vx-tooltip>
                <vx-tooltip text="Delete" position="top" delay=".3s">
                  <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    @click="confirmDeleteRecord(data[indextr], indextr)"
                    icon="icon-trash"
                    color="danger"
                  ></vs-button>
                </vx-tooltip>
              </vs-row>
            </vs-td>
          </vs-tr>
        </template>
</vs-table>
<span class="mr-2">{{ currentPage * dataTableParams.limit - (dataTableParams.limit - 1) }}
        -
        {{
          totalDocs - currentPage * dataTableParams.limit > 0
            ? currentPage * dataTableParams.limit
            : totalDocs
        }}
        of {{ totalDocs }}</span
      >
      <vs-pagination
        :total="totalPage"
        v-model="dataTableParams.page"
      ></vs-pagination>
    </div>
  </vx-card>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";
import { auctionStatus } from "../../components/constants/enums"

export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 10,
        filter: "All",
        status: "isListed"
      },
      vehicles: [],
      limitOptions: [5, 10, 25, 50, 100],
      filterOptions: ["All", "Private (all)", "Private (classified)", "Private (auction)", "Dealership"],
      selectedCar: {},

      fileName: "",
      cellAutoWidth: true,
      selectedFormat: "csv",
      headerTitle: [
        "Id",
        "Registration Upto Date",
        "Full Service Available",
        "Has Written Off",
        "Seller Type",
        "Allow Sms",
        "Allow Call",
        "Auction",
        "Parked",
        "Listed",
        "Drafted",
        "Licence Plate",
        "Year",
        "Transmission",
        "Transmission Type",
        "Engine Capacity",
        "Cylinder",
        "Registration Expiry Date",
        "Registration State",
        "Body Type",
        "Variant",
        "Series",
        "Vin Number",
        "Make",
        "Exterior Color",
        "Interior Color",
        "Drive Type",
        "Model",
        "Created At",
        "Updated At",
        "Asking Price",
        "Price Type",
        "Description",
        "Kilo Meter",
        "Contact Name",
        "Contact Number",
        "Display Address",
        "Email",
        "Mailing Address",
        "Owner Email",
        "Owner Name", 
        "Stock Number",
        "DealerID", 
      ],
      headerVal: [
        "_id",
        "isRegistrationUptoDate",
        "fullServiceAvailable",
        "hasWrittenOff",
        "sellerType",
        "allowSms",
        "allowCall",
        "isAuction",
        "isParked",
        "isListed",
        "isDrafted",
        "licencePlate",
        "year",
        "transmission",
        "transmissionType",
        "engineCapacity",
        "cylinder",
        "registrationExpiryDate",
        "registrationState",
        "bodyType",
        "badge",
        "series",
        "vinNumber",
        "make",
        "exteriorColor",
        "interiorColor",
        "drv",
        "model",
        "createdAt",
        "updatedAt",
        "askingPrice",
        "priceTag",
        "description",
        "kilometer",
        "contactName",
        "contactNumber",
        "displayAddress",
        "email",
        "mailingAddress",
        "ownerEmail",
        "ownerName",
        "stockNumber",
        "dealerId",
      ],
      selectedVehiclesForCsv: [],
      activePrompt: false,
    };
  },
  methods: {
    ...mapActions("admin", [
      "fetchVehicles",
      "fetchVehiclesForCsv",
      "deleteCar",
    ]),
    ...mapActions(["updateVehiclePage", "initToFirstPage", "updateVehiclePageLimit", "updateVehiclePageFilter", "updateVehiclePageSearch"]),
    moment(date) {
      return moment(date);
    },
    getVehicleForCSV() {
      let self = this;
      this.fetchVehiclesForCsv(self.dataTableParams).then((res) => {
        this.activePrompt = true;
        self.selectedVehiclesForCsv = res.data.data.docs;
      });
    },
    getVehiclesList() {
      let self = this;
      this.fetchVehicles(self.dataTableParams).then((res) => {
        self.vehicles = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
        self.currentPage = self.page;
      });
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getVehiclesList();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getVehiclesList();
    },
    viewDetailHandler(id) {
      this.$router.push({ name: "VehicleDetailsApproved", params: { id: id } });
    },
    editDetailHandler(carId) {
      this.$router.push({ name: "EditVehicle", params: { carId: carId } });
    },

    confirmDeleteRecord(data, i) {
      this.selectedCar = data;
      this.selectedIndex = i;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm Delete`,
        text: `You are about to delete "${data.make}"`,
        accept: this.deleteCarDetail,
        acceptText: "Delete",
      });
    },

    deleteCarDetail() {
      this.deleteCar(this.selectedCar._id).then((res) => {
        this.$vs.notify({
          title: "Vehicle Deleted",
          text: res.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.vehicles.splice(this.selectedIndex, 1);
        this.totalDocs -= 1;
      });
    },
    exportToExcel() {
      import("@/vendor/Export2Excel").then((excel) => {
        const list = this.csvData;
        const data = this.formatJson(this.headerVal, list);
        console.log({ data });
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat,
        });
        this.clearFields();
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          // Add col name which needs to be translated
          if (j === "images") {
            // v[j] = v[j].replace("http", "\nhttp");
            return v[j];
            // return parseTime(v[j]);
          }
          else if (j === "createdAt"){
            v[j] = moment(v[j]).format("DD/MM/YYYY")
            return v[j]
          }
          else if (j === "updatedAt"){
            v[j] = moment(v[j]).format("DD/MM/YYYY")
            return v[j]
          }
          else if (j === "registrationExpiryDate"){
            if(v[j] && v[j] !== 'Refer Seller') {
              v[j] = moment(v[j]).format("DD/MM/YYYY")
            } else{
              v[j] = "Refer Seller"
            }
            return v[j]
            
          }
          else {
            return v[j] ? v[j] : "Refer Seller"
          }
          // return v[j];
        })
      );
    },
    clearFields() {
      this.fileName = "";
      this.cellAutoWidth = true;
      this.selectedFormat = "csv";
    },
    checkSellerType(type, isAuction) {
          if (type === "dealership") {
              return "Dealer"
          }
          else {
              if (isAuction == true) {
                  return "Private (auction)"
              } else {
                  return "Private (classified)"
              }
          }
      },
    formatImage(images) {
      return "Refer Seller";
    },
    formatAuctionStatus(status) {
      switch (status) {
        case auctionStatus.COMPLETED_SOLD:
          return "Completed - Sold"
        case auctionStatus.COMPLETED_RESERVED_NOT_MET:
          return "Completed - Reserve not met"
        case auctionStatus.LIVE_RESERVE_MET:
          return "Live (reserve met)"
        case auctionStatus.LIVE_RESERVE_NOT_MET:
          return "Live (reserve not met)"
        case auctionStatus.LIVE:
          return "Live"
        default:
          return "Listed"
      }
    },
  },
  filters: {
    // Filter definitions
    capitalize(string) {
      return string.charAt(0).toString().toUpperCase() + string.slice(1);
    }
    },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
    csvData: function(){
      return this.selectedVehiclesForCsv.map(vehicle => {
        return {
          _id: vehicle._id,
          createdAt: vehicle.createdAt,
          updatedAt: vehicle.updatedAt,
          model: vehicle.model,
          make: vehicle.make,
          year: vehicle.year,
          images: this.formatImage(vehicle.images),
          series: vehicle.series?vehicle.series:"Refer Seller",
          bodyType: vehicle.bodyType?vehicle.bodyType:"Refer Seller",
          transmission: vehicle.transmission?vehicle.transmission:"Refer Seller",
          transmissionType: vehicle.transmissionType?vehicle.transmissionType:"Refer Seller",
          badge: vehicle.badge? vehicle.badge:"Refer Seller",
          cylinder: vehicle.cylinder?vehicle.cylinder:"Refer Seller",
          engineCapacity: vehicle.engineCapacity?vehicle.engineCapacity:"Refer Seller",
          askingPrice: vehicle.askingPrice,
          priceTag: (vehicle.priceTag && vehicle.sellerType == 'dealership')?(vehicle.priceTag =='DRIVE AWAY'?'DAP':'EGC'):(vehicle.sellerType == 'dealership'?'':'N/A'),
          kilometer: vehicle.kilometer,
          interiorColor: vehicle.interiorColor,
          exteriorColor: vehicle.exteriorColor,
          drv: vehicle.drv ? vehicle.drv : "Refer Seller",
          description: vehicle.description ? vehicle.description : "Refer Seller",
          registrationState: vehicle.registrationState ? vehicle.registrationState : "Refer Seller",
          fullServiceAvailable: vehicle.fullServiceAvailable ? vehicle.fullServiceAvailable : "Refer Seller",
          hasWrittenOff: vehicle.hasWrittenOff ? vehicle.hasWrittenOff : "Refer Seller",
          isListed: vehicle.isListed ? vehicle.isListed : "FALSE",
          isAuction: vehicle.isAuction ? vehicle.isAuction : "FALSE",
          isParked: vehicle.isParked,
          isDrafted: vehicle.isDrafted ? vehicle.isDrafted : "FALSE",
          allowCall: vehicle.allowCall ? vehicle.allowCall : "Refer Seller",
          allowSms: vehicle.allowSms ? vehicle.allowSms : "Refer Seller",
          isRegistrationUptoDate: vehicle.isRegistrationUptoDate ? vehicle.isRegistrationUptoDate : "Refer Seller",
          registrationExpiryDate: vehicle.registrationExpiryDate ? vehicle.registrationExpiryDate : "Refer Seller",
          licencePlate: vehicle.licencePlate ? vehicle.licencePlate : "Refer Seller",
          sellerType: vehicle.sellerType,
          roadworthyCertificate: vehicle.roadworthyCertificate ? vehicle.roadworthyCertificate : "Refer Seller",
          vinNumber: vehicle.vinNumber ? vehicle.vinNumber : "Refer Seller",
          displayAddress: vehicle.displayAddress,
          mailingAddress: vehicle.mailingAddress,
          contactName: vehicle.contactName,
          contactNumber: vehicle.contactNumber,
          email: vehicle.email,
          ownerName: vehicle.dealerId ? vehicle.dealerId.name : vehicle.userId ? vehicle.userId.firstName: "N/A",
          ownerEmail: vehicle.dealerId ? vehicle.dealerId.contactInformation.email : vehicle.userId ? vehicle.userId.email: "N/A",
          stockNumber: vehicle.dealerId ? vehicle.stockNumber : "N/A",
          dealerId: vehicle.dealerId ? vehicle.dealerId.dealerID : "N/A",
        }
      })
    }
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateVehiclePage(newVal);
        this.dataTableParams.page = newVal;
        this.getVehiclesList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.updateVehiclePageLimit(newlimit);
        // this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getVehiclesList();
      }
    },
    "dataTableParams.filter": function (newFilter, oldFilter) {
      if (newFilter !== oldFilter) {
        this.updateVehiclePageFilter(newFilter);
        // this.dataTableParams.page = 1;
        this.dataTableParams.filter = newFilter;
        this.getVehiclesList();
      }
    },
    "dataTableParams.search": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = 1;
        this.updateVehiclePageSearch(newVal)
        this.dataTableParams.search = newVal;
        this.getVehiclesList();
      }
    },
  },
  created() {
    this.initToFirstPage({pageType: "vehicle"});
    this.getVehiclesList();
  },
};
</script> 
<style>
.img-container {
  /* border: 1px solid #ccc; */
  float: left;
  width: 150px;
  height:100px;
  display: flex;
  justify-content: center;
}

.image img {
  max-width: 150px;
  max-height: 100px;
  height: auto;
  object-fit: contain;
}
.image {
  display:flex;
  align-self: center;
}
.v-select-limit-container {
  min-width: 90px;
}
.v-select-filter-container {
  min-width: 130px;
}
</style>
